<template>

  <b-row>

    <!-- Left -->
    <b-col
      cols="12"
      lg="9"
    >

      <b-alert
        :show="userData.role === 'admin' && formUpdateStatus === 1"
        variant="warning"
        style="padding: 0.71rem 1rem; border: 1px solid transparent;"
      >
        Please see the following list of requested changes.
        <ul
          class="d-flex flex-column flex-wrap mt-50"
          style="max-height: 300px;"
        >
          <li
            v-for="(value, index) in formUpdateChanges"
            :key="index"
          >
            {{ value }}
          </li>
        </ul>
        <hr>
        <div class="text-right">
          <b-button
            variant="danger"
            class="mr-1"
            @click="denyFormUpdate"
          >
            Deny
          </b-button>
          <b-button
            variant="secondary"
            @click="approveFormUpdate"
          >
            Approve
          </b-button>
        </div>
      </b-alert>

      <b-alert
        :show="userData.role === 'agent' && formUpdateStatus === 1"
        variant="warning"
        style="padding: 0.71rem 1rem; border: 1px solid transparent;"
      >
        You still have pending profile update requests. Please wait for the Admin to review them before making any other changes.
      </b-alert>

      <b-alert
        :show="userData.role === 'agent' && formUpdateStatus === 2"
        variant="primary"
        dismissible
        fade
        style="padding: 0.71rem 1rem; cursor: pointer; border: 1px solid transparent;"
        @dismissed="dismissFormUpdate"
      >
        The HR/Admin has approved the changes you made.
      </b-alert>

      <b-alert
        :show="userData.role === 'agent' && formUpdateStatus === 3"
        variant="danger"
        dismissible
        fade
        style="border: 1px solid transparent;"
        @dismissed="dismissFormUpdate"
      >
        <div
          style="padding: 0.71rem 1rem; cursor: pointer;"
          @click="dismissFormUpdate"
        >
          The HR/Admin has denied the changes you made.
        </div>
      </b-alert>

      <b-overlay
        :show="userData.role === 'agent' && formUpdateStatus > 0"
        spinner-type="false"
        opacity="0.25"
        blur
        rounded
      >

        <!-- Card -->
        <b-card>

          <b-row class="hr-form">
            <b-col
              cols="12"
            >

              <b-card-title>
                Form W-4
                <br>
                <small>(Rev. December 2020) Department of the Treasury Internal Revenue Service</small>
              </b-card-title>

              <b>Employee’s Withholding Certificate</b>
              <ul>
                <li>Complete Form W-4 so that your employer can withhold the correct federal income tax from your pay.</li>
                <li>Give Form W-4 to your employer.</li>
                <li>Your withholding is subject to review by the IRS.</li>
              </ul>

              <!-- Spacer -->
              <hr>

              <!-- Form -->
              <validation-observer ref="simpleRules">
                <b-form>

                  <b-form-input
                    v-model="form.form"
                    type="text"
                    class="hidden"
                  />

                  <b-form-input
                    v-model="form.id"
                    type="text"
                    class="hidden"
                  />

                  <h4>Step 1: Enter Personal Information</h4>

                  <b-row>

                    <!-- Input First Name and Middle Initial -->
                    <b-col
                      cols="12"
                      lg="8"
                    >

                      <b-form-group
                        label="* First Name and Middle Initial"
                        label-for="first-name-middle-initial"
                      >
                        <label
                          class="sr-only"
                          for="first-name-middle-initial"
                        >
                          * First Name and Middle Initial
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="First Name and Middle Initial"
                          vid="first-name-middle-initial"
                          rules="required"
                        >
                          <b-form-input
                            id="first-name-middle-initial"
                            v-model="firstNameMiddleInitial"
                            :state="errors.length > 0 ? false : null"
                            type="text"
                            placeholder="First Name and Middle Initial"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <!-- Input Last Name -->
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="* Last Name"
                        label-for="last-name"
                      >
                        <label
                          class="sr-only"
                          for="last-name"
                        >
                          * Last Name
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Last Name"
                          vid="last-name"
                          rules="required"
                        >
                          <b-form-input
                            id="last-name"
                            v-model="lastName"
                            :state="errors.length > 0 ? false : null"
                            type="text"
                            placeholder="Last Name"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <b-row>

                    <!-- Input Address -->
                    <b-col
                      cols="12"
                      lg="8"
                    >

                      <b-form-group
                        label="* Address"
                        label-for="address"
                      >
                        <label
                          class="sr-only"
                          for="address"
                        >
                          * Address
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Address"
                          vid="address"
                          rules="required"
                        >
                          <b-form-input
                            id="address"
                            v-model="address"
                            :state="errors.length > 0 ? false : null"
                            type="text"
                            placeholder="Address"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <b-row>

                    <!-- Input City or Town, State, and ZIP Code -->
                    <b-col
                      cols="12"
                    >

                      <b-form-group
                        label="* City or Town, State, and ZIP Code"
                        label-for="city-town-state-zip-code"
                      >
                        <label
                          class="sr-only"
                          for="city-town-state-zip-code"
                        >
                          * City or Town, State, and ZIP Code
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="City or Town, State, and ZIP Code"
                          vid="city-town-state-zip-code"
                          rules="required"
                        >
                          <b-form-input
                            id="city-town-state-zip-code"
                            v-model="cityTownStateZipCode"
                            :state="errors.length > 0 ? false : null"
                            type="text"
                            placeholder="City or Town, State, and ZIP Code"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <!-- Spacer -->
                  <br>

                  <b-row>

                    <!-- Input Social Security Number -->
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="Social Security Number"
                        label-for="social-security-number"
                      >
                        <label
                          class="sr-only"
                          for="social-security-number"
                        >
                          Social Security Number
                        </label>
                        <b-form-input
                          id="social-security-number"
                          v-model="socialSecurityNumber"
                          type="text"
                          placeholder="Social Security Number"
                        />
                      </b-form-group>

                    </b-col>

                    <b-col>
                      <span><b>Does your name match the name on your social security card?</b> If not, to ensure you get credit for your earnings, contact SSA at <a href="tel:+18007721213">1-800-772-1213</a> or go to <a href="https://www.ssa.gov/">www.ssa.gov</a>.</span>
                    </b-col>
                  </b-row>

                  <!-- Spacer -->
                  <br>

                  <!-- Checkbox Step 1 Field 6 -->
                  <b-row>

                    <b-col
                      cols="12"
                    >

                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Step 1 Field 6"
                          rules="required"
                        >
                          <b-form-radio
                            ref="step1Field6"
                            v-model="form.step1Field6"
                            :state="errors.length > 0 ? false : null"
                            value="Single or Married filing separately"
                            title="Step 1 Field 6"
                          >
                            <b>Single</b> or <b>Married filing separately</b>
                          </b-form-radio>
                          <b-form-radio
                            ref="step1Field6"
                            v-model="form.step1Field6"
                            :state="errors.length > 0 ? false : null"
                            value="Married filing jointly or Qualifying widow(er)"
                            title="Step 1 Field 6"
                          >
                            <b>Married filing jointly</b> or <b>Qualifying widow(er)</b>
                          </b-form-radio>
                          <b-form-radio
                            ref="step1Field6"
                            v-model="form.step1Field6"
                            :state="errors.length > 0 ? false : null"
                            value="Head of household (Check only if you’re unmarried and pay more than half the costs of keeping up a home for yourself and a qualifying individual.)"
                            title="Step 1 Field 6"
                          >
                            <b>Head of household</b> (Check only if you’re unmarried and pay more than half the costs of keeping up a home for yourself and a qualifying individual.)
                          </b-form-radio>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <span><b>Complete Steps 2-4 ONLY if they apply to you; otherwise, skip to Step 5.</b> See page 2 for more information on each step, who can claim exemption from withholding, when to use the estimator at <a href="https://www.irs.gov/W4App">www.irs.gov/W4App</a>, and privacy.</span>

                  <!-- Spacer -->
                  <hr>

                  <h4>Step 2: Multiple Jobs or Spouse Works</h4>

                  <span>Complete this step if you (1) hold more than one job at a time, or (2) are married filing jointly and your spouse  also works. The correct amount of withholding depends on income earned from all of these jobs.</span>
                  <br>
                  <span>Do <b>only one</b> of the following.</span>
                  <ol type="a">
                    <li>Use the estimator at <a href="https://www.irs.gov/W4App">www.irs.gov/W4App</a> for most accurate withholding for this step (and Steps 3-4); <b>or</b></li>
                    <li>Use the Multiple Jobs Worksheet on page 3 and enter the result in Step 4(c) below for roughly accurate withholding; <b>or</b></li>
                    <li>
                      If there are only two jobs total, you may check this box. Do the same on Form W-4 for the other job. This option is accurate for jobs with similar pay; otherwise, more tax than necessary may be withheld.

                      <!-- Step 2 Field 1 -->
                      <b-row>

                        <b-col
                          cols="12"
                          lg="4"
                        >

                          <b-form-group
                            class="mb-0"
                          >
                            <b-form-checkbox
                              ref="step2Field1"
                              v-model="form.step2Field1"
                              value="true"
                              title="Step 2 Field 1"
                            >
                              <b>Click here to check this option</b>
                            </b-form-checkbox>
                          </b-form-group>

                        </b-col>
                      </b-row>
                    </li>
                  </ol>

                  <p><b>TIP:</b> To be accurate, submit a 2021 Form W-4 for all other jobs. If you (or your spouse) have self-employment income, including as an independent contractor, use the estimator.</p>

                  <span><b>Complete Steps 3-4(b) on Form W-4 for only ONE of these jobs.</b> Leave those steps blank for the other jobs. (Your withholding will be most accurate if you complete Steps 3-4(b) on the Form W-4 for the highest paying job.)</span>

                  <!-- Spacer -->
                  <hr>

                  <h4>Step 3: Claim Dependents</h4>

                  <p>If your total income will be $200,000 or less ($400,000 or less if married filing jointly):</p>

                  <b-row>

                    <!-- Input Step 3 Field 1 -->
                    <b-col
                      cols="12"
                      lg="8"
                    >

                      <b-form-group
                        label="* Multiply the number of qualifying children under age 17 by $2,000."
                        label-for="step3-field1"
                      >
                        <label
                          class="sr-only"
                          for="step3-field1"
                        >
                          * Multiply the number of qualifying children under age 17 by $2,000.
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Step 3 Field 1"
                          vid="step3-field1"
                          rules="required"
                        >
                          <b-input-group>
                            <b-input-group-prepend is-text>
                              $
                            </b-input-group-prepend>
                            <b-form-input
                              id="step3-field1"
                              ref="step3Field1"
                              v-model="form.step3Field1"
                              :state="errors.length > 0 ? false : null"
                              type="number"
                              placeholder="Answer"
                              title="Step 3 Field 1"
                            />
                          </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <b-row>

                    <!-- Input Step 3 Field 2 -->
                    <b-col
                      cols="12"
                      lg="8"
                    >

                      <b-form-group
                        label="* Multiply the number of other dependents by $500."
                        label-for="last-name"
                      >
                        <label
                          class="sr-only"
                          for="last-name"
                        >
                          * Multiply the number of other dependents by $500.
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Step 3 Field 2"
                          vid="step3-field2"
                          rules="required"
                        >
                          <b-input-group>
                            <b-input-group-prepend is-text>
                              $
                            </b-input-group-prepend>
                            <b-form-input
                              id="step3-field2"
                              ref="step3Field2"
                              v-model="form.step3Field2"
                              :state="errors.length > 0 ? false : null"
                              type="number"
                              placeholder="Answer"
                              title="Step 3 Field 2"
                            />
                          </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <!-- Input Step 3 Field 3 -->

                  <p>Add the amounts above and enter the total here.</p>

                  <b-row>

                    <b-col
                      cols="12"
                      lg="8"
                    >

                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Step 3 Field 3"
                          vid="step3-field3"
                        >
                          <b-input-group>
                            <b-input-group-prepend is-text>
                              3
                            </b-input-group-prepend>
                            <b-input-group-prepend is-text>
                              $
                            </b-input-group-prepend>
                            <b-form-input
                              id="step3-field3"
                              ref="step3Field3"
                              v-model="form.step3Field3"
                              :state="errors.length > 0 ? false : null"
                              type="number"
                              placeholder="Answer"
                              title="Step 3 Field 3"
                            />
                          </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <!-- Spacer -->
                  <hr>

                  <h4>Step 4 (optional): Other Adjustments</h4>

                  <!-- Input Step 4 Field 1 -->

                  <p><b>Other income (not from jobs).</b> If you want tax withheld for other income you expect this year that won’t have withholding, enter the amount of other income here. This may include interest, dividends, and retirement income.</p>

                  <b-row>

                    <b-col
                      cols="12"
                      lg="8"
                    >

                      <b-form-group>
                        <b-input-group>
                          <b-input-group-prepend is-text>
                            4(a)
                          </b-input-group-prepend>
                          <b-input-group-prepend is-text>
                            $
                          </b-input-group-prepend>
                          <b-form-input
                            id="step4-field1"
                            ref="step4Field1"
                            v-model="form.step4Field1"
                            type="number"
                            placeholder="Answer"
                            title="Step 4 Field 1"
                          />
                        </b-input-group>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <!-- Input Step 4 Field 2 -->

                  <p><b>Deductions.</b> If you expect to claim deductions other than the standard deduction and want to reduce your withholding, use the Deductions Worksheet on page 3 and enter the result here.</p>

                  <b-row>

                    <b-col
                      cols="12"
                      lg="8"
                    >

                      <b-form-group>
                        <b-input-group>
                          <b-input-group-prepend is-text>
                            4(b)
                          </b-input-group-prepend>
                          <b-input-group-prepend is-text>
                            $
                          </b-input-group-prepend>
                          <b-form-input
                            id="step4-field2"
                            ref="step4Field2"
                            v-model="form.step4Field2"
                            type="number"
                            placeholder="Answer"
                            title="Step 4 Field 2"
                          />
                        </b-input-group>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <!-- Input Step 4 Field 3 -->

                  <p><b>Extra withholding.</b> Enter any additional tax you want withheld each <b>pay period</b>.</p>

                  <b-row>

                    <b-col
                      cols="12"
                      lg="8"
                    >

                      <b-form-group>
                        <b-input-group>
                          <b-input-group-prepend is-text>
                            4(c)
                          </b-input-group-prepend>
                          <b-input-group-prepend is-text>
                            $
                          </b-input-group-prepend>
                          <b-form-input
                            id="step4-field3"
                            ref="step4Field3"
                            v-model="form.step4Field3"
                            type="number"
                            placeholder="Answer"
                            title="Step 4 Field 3"
                          />
                        </b-input-group>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <!-- Spacer -->
                  <hr>

                  <h4>Step 5: Sign Here <em>(This form is not valid unless you sign it.)</em></h4>

                  <p>Under penalties of perjury, I declare that this certificate, to the best of my knowledge and belief, is true, correct, and complete.</p>

                  <!-- Input Signature -->
                  <b-row>

                    <b-col
                      cols="12"
                    >

                      <b-form-group
                        label="* Employee Signature"
                        label-for="signature"
                      >
                        <label
                          class="sr-only"
                          for="signature"
                        >
                          * Employee Signature
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Employee Signature"
                          vid="employee-signature"
                          rules="required"
                        >
                          <vue-signature-pad
                            id="employee-signature"
                            ref="signature"
                            v-model="form.signature"
                            :state="errors.length > 0 ? false : null"
                            width="100%"
                            height="150px"
                            :options="{ onBegin, onEnd }"
                            class="form-control"
                            :class="{ 'is-invalid' : errors.length > 0 && form.signature === '' }"
                            title="Employee Signature"
                          />

                          <b-row>

                            <b-col>
                              <small
                                v-if="form.signature === ''"
                                class="text-danger"
                              >
                                {{ errors[0] }}
                              </small>
                            </b-col>

                            <b-col
                              class="text-right mt-1"
                            >
                              <!-- Button Undo -->
                              <b-button
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                variant="outline-dark"
                                class="mr-1"
                                @click="undoSignature"
                              >
                                Undo
                              </b-button>

                              <!-- Button Clear -->
                              <b-button
                                variant="outline-danger"
                                @click="clearSignature"
                              >
                                Clear
                              </b-button>
                            </b-col>
                          </b-row>

                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <!-- Input Date Signed -->
                  <b-row>

                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <validation-provider
                        #default="{ errors }"
                        name="Date Signed"
                        vid="date-signed"
                        rules="required"
                      >
                        <b-form-group
                          label="* Date Signed"
                          label-for="date-signed"
                          :state="errors.length > 0 ? false : null"
                        >
                          <label
                            class="sr-only"
                            for="date-signed"
                          >
                            * Date Signed
                          </label>
                          <flat-pickr
                            id="date-signed"
                            ref="dateSigned"
                            v-model="form.dateSigned"
                            class="form-control"
                            :config="flatPickrConfig"
                            placeholder="Choose a date"
                            title="Date Signed"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>

                    </b-col>
                  </b-row>

                  <!-- Spacer -->
                  <hr>

                  <h4>Employers Only</h4>

                  <b-row>

                    <!-- Input Employer's Name and Address -->
                    <b-col
                      cols="12"
                      lg="8"
                    >

                      <b-form-group
                        label="* Employer's Name and Address"
                        label-for="employers-name-address"
                      >
                        <label
                          class="sr-only"
                          for="employers-name-address"
                        >
                          * Employer's Name and Address
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Employer's Name and Address"
                          vid="employers-name-address"
                          rules="required"
                        >
                          <b-form-input
                            id="employers-name-address"
                            v-model="employersNameAddress"
                            :state="errors.length > 0 ? false : null"
                            type="text"
                            placeholder="Employer's Name and Address"
                            readonly
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <!-- Input First Day of Employment -->
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <validation-provider
                        #default="{ errors }"
                        name="First Day of Employment"
                        vid="first-day-of-employment"
                        rules="required"
                      >
                        <b-form-group
                          label="* First Day of Employment"
                          label-for="first-day-of-employment"
                          :state="errors.length > 0 ? false : null"
                        >
                          <label
                            class="sr-only"
                            for="first-day-of-employment"
                          >
                            * First Day of Employment
                          </label>
                          <flat-pickr
                            id="first-day-of-employment"
                            v-model="firstDayOfEmployment"
                            class="form-control"
                            :config="flatPickrConfig"
                            placeholder="Choose a date"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>

                    </b-col>
                  </b-row>

                  <b-row>

                    <!-- Input Employer Identification Number -->
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="* Employer Identification Number (EIN)"
                        label-for="employer-identification-number"
                      >
                        <label
                          class="sr-only"
                          for="employer-identification-number"
                        >
                          * Employer Identification Number (EIN)
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Employer Identification Number"
                          vid="employer-identification-number"
                          rules="required"
                        >
                          <b-form-input
                            id="employer-identification-number"
                            v-model="employerIdentificationNumber"
                            :state="errors.length > 0 ? false : null"
                            type="text"
                            placeholder="Employer Identification Number"
                            readonly
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <!-- Spacer -->
                  <hr>

                  <h4>Step 2(b) - Multiple Jobs Worksheet <em>(Keep for your records.)</em></h4>

                  <p>If you choose the option in Step 2(b) on Form W-4, complete this worksheet (which calculates the total extra tax for all jobs) on <b>only ONE</b> Form W-4. Withholding will be most accurate if you complete the worksheet and enter the result on the Form W-4 for the highest paying job.</p>

                  <p><b>Note:</b> If more than one job has annual wages of more than $120,000 or there are more than three jobs, see Pub. 505 for additional tables; or, you can use the online withholding estimator at <a href="https://www.irs.gov/W4App">www.irs.gov/W4App</a>.</p>

                  <!-- Input Step 2(b) Field 1 -->

                  <p><b>Two jobs.</b> If you have two jobs or you’re married filing jointly and you and your spouse each have one job, find the amount from the appropriate table on page 4. Using the “Higher Paying Job” row and the “Lower Paying Job” column, find the value at the intersection of the two household salaries and enter that value on line 1. Then, <b>skip</b> to line 3.</p>

                  <b-row>

                    <b-col
                      cols="12"
                      lg="8"
                    >

                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Step 2(b) Field 1"
                          vid="step2b-filed1"
                        >
                          <b-input-group>
                            <b-input-group-prepend is-text>
                              1
                            </b-input-group-prepend>
                            <b-input-group-prepend is-text>
                              $
                            </b-input-group-prepend>
                            <b-form-input
                              id="step2b-filed1"
                              ref="step2bField1"
                              v-model="form.step2bField1"
                              :state="errors.length > 0 ? false : null"
                              type="number"
                              placeholder="Answer"
                              title="Step 2(b) Field 1"
                            />
                          </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <!-- Spacer -->
                  <br>

                  <!-- Input Step 2(b) Field 2 -->

                  <p><b>Three jobs.</b> If you and/or your spouse have three jobs at the same time, complete lines 2a, 2b, and 2c below. Otherwise, skip to line 3.</p>

                  <p>Find the amount from the appropriate table on page 4 using the annual wages from the highest paying job in the “Higher Paying Job” row and the annual wages for your next highest paying job in the “Lower Paying Job” column. Find the value at the intersection of the two household salaries and enter that value on line 2a.</p>

                  <b-row>

                    <b-col
                      cols="12"
                      lg="8"
                    >

                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Step 2(b) Field 2a"
                          vid="step2b-filed2a"
                        >
                          <b-input-group>
                            <b-input-group-prepend is-text>
                              2a
                            </b-input-group-prepend>
                            <b-input-group-prepend is-text>
                              $
                            </b-input-group-prepend>
                            <b-form-input
                              id="step2b-filed2a"
                              ref="step2bField2a"
                              v-model="form.step2bField2a"
                              :state="errors.length > 0 ? false : null"
                              type="number"
                              placeholder="Answer"
                              title="Step 2(b) Field 2a"
                            />
                          </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <p>Add the annual wages of the two highest paying jobs from line 2a together and use the total as the wages in the “Higher Paying Job” row and use the annual wages for your third job in the “Lower Paying Job” column to find the amount from the appropriate table on page 4 and enter this amount on line 2b.</p>

                  <b-row>

                    <b-col
                      cols="12"
                      lg="8"
                    >

                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Step 2(b) Field 2b"
                          vid="step2b-filed2b"
                        >
                          <b-input-group>
                            <b-input-group-prepend is-text>
                              2b
                            </b-input-group-prepend>
                            <b-input-group-prepend is-text>
                              $
                            </b-input-group-prepend>
                            <b-form-input
                              id="step2b-filed2b"
                              ref="step2bField2b"
                              v-model="form.step2bField2b"
                              :state="errors.length > 0 ? false : null"
                              type="number"
                              placeholder="Answer"
                              title="Step 2(b) Field 2b"
                            />
                          </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <p>Add the amounts from lines 2a and 2b and enter the result on line 2c.</p>

                  <b-row>

                    <b-col
                      cols="12"
                      lg="8"
                    >

                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Step 2(b) Field 2c"
                          vid="step2b-filed2c"
                        >
                          <b-input-group>
                            <b-input-group-prepend is-text>
                              2c
                            </b-input-group-prepend>
                            <b-input-group-prepend is-text>
                              $
                            </b-input-group-prepend>
                            <b-form-input
                              id="step2b-filed2c"
                              ref="step2bField2c"
                              v-model="form.step2bField2c"
                              :state="errors.length > 0 ? false : null"
                              type="number"
                              placeholder="Answer"
                              title="Step 2(b) Field 2c"
                            />
                          </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <!-- Spacer -->
                  <br>

                  <!-- Input Step 2(b) Field 3 -->

                  <p>Enter the number of pay periods per year for the highest paying job. For example, if that job pays weekly, enter 52; if it pays every other week, enter 26; if it pays monthly, enter 12, etc.</p>

                  <b-row>

                    <b-col
                      cols="12"
                      lg="8"
                    >

                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Step 2(b) Field 3"
                          vid="step2b-filed3"
                        >
                          <b-input-group>
                            <b-input-group-prepend is-text>
                              3
                            </b-input-group-prepend>
                            <b-input-group-prepend is-text>
                              $
                            </b-input-group-prepend>
                            <b-form-input
                              id="step2b-filed3"
                              ref="step2bField3"
                              v-model="form.step2bField3"
                              :state="errors.length > 0 ? false : null"
                              type="number"
                              placeholder="Answer"
                              title="Step 2(b) Field 3"
                            />
                          </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <!-- Spacer -->
                  <br>

                  <!-- Input Step 2(b) Field 4 -->

                  <p><b>Divide</b> the annual amount on line 1 or line 2c by the number of pay periods on line 3. Enter this amount here and in <b>Step 4(c)</b> of Form W-4 for the highest paying job (along with any other additional amount you want withheld).</p>

                  <b-row>

                    <b-col
                      cols="12"
                      lg="8"
                    >

                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Step 2(b) Field 4"
                          vid="step2b-filed4"
                        >
                          <b-input-group>
                            <b-input-group-prepend is-text>
                              4
                            </b-input-group-prepend>
                            <b-input-group-prepend is-text>
                              $
                            </b-input-group-prepend>
                            <b-form-input
                              id="step2b-filed4"
                              ref="step2bField4"
                              v-model="form.step2bField4"
                              :state="errors.length > 0 ? false : null"
                              type="number"
                              placeholder="Answer"
                              title="Step 2(b) Field 4"
                            />
                          </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <!-- Spacer -->
                  <hr>

                  <h4>Step 4(b) - Deductions Worksheet <em>(Keep for your records.)</em></h4>

                  <!-- Input Step 4(b) Field 1 -->

                  <p>Enter an estimate of your 2021 itemized deductions (from Schedule A (Form 1040)). Such deductions may include qualifying home mortgage interest, charitable contributions, state and local taxes (up to $10,000), and medical expenses in excess of 7.5% of your income.</p>

                  <b-row>

                    <b-col
                      cols="12"
                      lg="8"
                    >

                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Step 4(b) Field 1"
                          vid="step4b-filed1"
                        >
                          <b-input-group>
                            <b-input-group-prepend is-text>
                              1
                            </b-input-group-prepend>
                            <b-input-group-prepend is-text>
                              $
                            </b-input-group-prepend>
                            <b-form-input
                              id="step4b-filed1"
                              ref="step4bField1"
                              v-model="form.step4bField1"
                              :state="errors.length > 0 ? false : null"
                              type="number"
                              placeholder="Answer"
                              title="Step 4(b) Field 1"
                            />
                          </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <!-- Spacer -->
                  <br>

                  <!-- Input Step 4(b) Field 2 -->

                  <span>Enter:</span>
                  <ul>
                    <li>$25,100 if you’re married filing jointly or qualifying widow(er).</li>
                    <li>$18,800 if you’re head of household.</li>
                    <li>$12,550 if you’re single or married filing separately.</li>
                  </ul>

                  <b-row>

                    <b-col
                      cols="12"
                      lg="8"
                    >

                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Step 4(b) Field 2"
                          vid="step4b-filed2"
                        >
                          <b-input-group>
                            <b-input-group-prepend is-text>
                              2
                            </b-input-group-prepend>
                            <b-input-group-prepend is-text>
                              $
                            </b-input-group-prepend>
                            <b-form-input
                              id="step4b-filed2"
                              ref="step4bField2"
                              v-model="form.step4bField2"
                              :state="errors.length > 0 ? false : null"
                              type="number"
                              placeholder="Answer"
                              title="Step 4(b) Field 2"
                            />
                          </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <!-- Spacer -->
                  <br>

                  <!-- Input Step 4(b) Field 3 -->

                  <p>If line 1 is greater than line 2, subtract line 2 from line 1 and enter the result here. If line 2 is greater than line 1, enter “-0-”.</p>

                  <b-row>

                    <b-col
                      cols="12"
                      lg="8"
                    >

                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Step 4(b) Field 3"
                          vid="step4b-filed3"
                        >
                          <b-input-group>
                            <b-input-group-prepend is-text>
                              3
                            </b-input-group-prepend>
                            <b-input-group-prepend is-text>
                              $
                            </b-input-group-prepend>
                            <b-form-input
                              id="step4b-filed3"
                              ref="step4bField3"
                              v-model="form.step4bField3"
                              :state="errors.length > 0 ? false : null"
                              type="number"
                              placeholder="Answer"
                              title="Step 4(b) Field 3"
                            />
                          </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <!-- Spacer -->
                  <br>

                  <!-- Input Step 4(b) Field 4 -->

                  <p>Enter an estimate of your student loan interest, deductible IRA contributions, and certain other adjustments (from Part II of Schedule 1 (Form 1040)). See Pub. 505 for more information.</p>

                  <b-row>

                    <b-col
                      cols="12"
                      lg="8"
                    >

                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Step 4(b) Field 4"
                          vid="step4b-filed4"
                        >
                          <b-input-group>
                            <b-input-group-prepend is-text>
                              4
                            </b-input-group-prepend>
                            <b-input-group-prepend is-text>
                              $
                            </b-input-group-prepend>
                            <b-form-input
                              id="step4b-filed4"
                              ref="step4bField4"
                              v-model="form.step4bField4"
                              :state="errors.length > 0 ? false : null"
                              type="number"
                              placeholder="Answer"
                              title="Step 4(b) Field 4"
                            />
                          </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <!-- Spacer -->
                  <br>

                  <!-- Input Step 4(b) Field 5 -->

                  <p><b>Add</b> lines 3 and 4. Enter the result here and in <b>Step 4(b)</b> of Form W-4.</p>

                  <b-row>

                    <b-col
                      cols="12"
                      lg="8"
                    >

                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Step 4(b) Field 5"
                          vid="step4b-filed5"
                        >
                          <b-input-group>
                            <b-input-group-prepend is-text>
                              5
                            </b-input-group-prepend>
                            <b-input-group-prepend is-text>
                              $
                            </b-input-group-prepend>
                            <b-form-input
                              id="step4b-filed5"
                              ref="step4bField5"
                              v-model="form.step4bField5"
                              :state="errors.length > 0 ? false : null"
                              type="number"
                              placeholder="Answer"
                              title="Step 4(b) Field 5"
                            />
                          </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <!-- Spacer -->
                  <hr>

                  <p><b>Privacy Act and Paperwork Reduction Act Notice.</b> We ask for the information on this form to carry out the Internal Revenue laws of the United States. Internal Revenue Code sections 3402(f)(2) and 6109 and their regulations require you to provide this information; your employer uses it to determine your federal income tax withholding. Failure to provide a properly completed form will result in your being treated as a single person with no other entries on the form; providing fraudulent information may subject you to penalties. Routine uses of this information include giving it to the Department of Justice for civil and criminal litigation; to cities, states, the District of Columbia, and U.S. commonwealths and possessions for use in administering their tax laws; and to the Department of Health and Human Services for use in the National Directory of New Hires. We may also disclose this information to other countries under a tax treaty, to federal and state agencies to enforce federal nontax criminal laws, or to federal law enforcement and intelligence agencies to combat terrorism.</p>
                  <p>You are not required to provide the information requested on a form that is subject to the Paperwork Reduction Act unless the form displays a valid OMB control number. Books or records relating to a form or its instructions must be retained as long as their contents may become material in the administration of any Internal Revenue law. Generally, tax returns and return information are confidential, as required by Code section 6103.</p>
                  <p>The average time and expenses required to complete and file this form will vary depending on individual circumstances. For estimated averages, see the instructions for your income tax return.</p>
                  <p>If you have suggestions for making this form simpler, we would be happy to hear from you. See the instructions for your income tax return.</p>

                  <!-- Button Submit -->
                  <b-button
                    variant="primary"
                    type="submit"
                    class="mr-1 d-none"
                    @click.prevent="validationForm"
                  >
                    Submit
                  </b-button>
                </b-form>
              </validation-observer>

            </b-col>
          </b-row>

        </b-card>

      </b-overlay>
    </b-col>

    <!-- Right -->
    <b-col
      cols="12"
      lg="3"
    >

      <!-- Action Buttons -->
      <b-card>

        <!-- Button Preview -->
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="gradient-main"
          class="mb-75 btn-print-preview"
          block
          @click="$store.dispatch('app-hr/preview')"
        >
          Print Preview
        </b-button>

        <!-- Button Save -->
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="secondary"
          block
          :disabled="(userData.role === 'agent' && formUpdateStatus > 0) ? true : isNotSaved"
          @click.prevent="validationForm"
        >
          Save
        </b-button>

        <!-- Button Cancel -->
        <b-button
          v-if="action !== 'preview'"
          variant="outline-dark"
          class="mb-75"
          :to="{ name: (userData.role === 'agent' ? 'caregiverLanding' : 'hr') }"
          block
        >
          Cancel
        </b-button>

        <div class="d-flex">
          <!-- Button Previous -->
          <b-button
            variant="outline-dark"
            class="flex-fill mr-25"
            :to="{ name: 'apps-hr-form5', params: { action: action }, query: { id: id } }"
          >
            Previous
          </b-button>

          <!-- Button Next -->
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="secondary"
            class="flex-fill ml-25"
            :to="{ name: 'apps-hr-form7', params: { action: action }, query: { id: id } }"
          >
            Next
          </b-button>
        </div>

        <!-- Spacer -->
        <hr class="mb-50">

        <label for="forms">Form Selection</label>
        <v-select
          id="forms"
          v-model="forms"
          :reduce="(option) => option.name"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="formsOptions"
          :clearable="false"
          :disabled="!id"
          class="hr-forms-select"
          @input="$router.push({ name: forms, params: { action: action }, query: { id: id } })"
        >
          <template #selected-option="{ label }">
            <span class="text-truncate overflow-hidden">
              {{ label }}
            </span>
          </template>
        </v-select>
      </b-card>

    </b-col>
  </b-row>

</template>

<script>
import SockJS from 'sockjs-client'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BOverlay, BAlert, BCard, BCardTitle, BRow, BCol, BForm, BFormGroup, BFormInput, BInputGroup, BInputGroupPrepend, BFormRadio, BFormCheckbox, BButton, VBToggle,
} from 'bootstrap-vue'
import { required } from '@validations'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { VueSignaturePad } from 'vue-signature-pad'
import Swal from 'sweetalert2'
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'

import { getUserData } from '@/auth/utils'

import hrStoreModule from '../hrStoreModule'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,

    BOverlay,
    BAlert,
    BCard,
    BCardTitle,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormRadio,
    BFormCheckbox,
    BButton,

    vSelect,
    flatPickr,
    VueSignaturePad,
    // eslint-disable-next-line
    Swal,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  setup() {
    const HR_APP_STORE_MODULE_NAME = 'app-hr'

    // Register module
    if (!store.hasModule(HR_APP_STORE_MODULE_NAME)) store.registerModule(HR_APP_STORE_MODULE_NAME, hrStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(HR_APP_STORE_MODULE_NAME)) store.unregisterModule(HR_APP_STORE_MODULE_NAME)
    })

    const form = ref({})

    const firstNameMiddleInitial = ref('')
    const lastName = ref('')
    const address = ref('')
    const cityTownStateZipCode = ref('')
    const socialSecurityNumber = ref('')
    const firstDayOfEmployment = ref('')

    const signature = ref(null)

    const formUpdate = ref({})
    const formUpdateStatus = ref(0)

    const formCopy = ref({})

    if (router.currentRoute.query.id) {
      store.dispatch('app-hr/fetchForm', { id: router.currentRoute.query.id })
        .then(response => {
          // eslint-disable-next-line
          const jsonObjectLiterals = response.data.response.data

          // console.log(jsonObjectLiterals.data)

          form.value = {}

          form.value.cid = router.currentRoute.query.id

          // eslint-disable-next-line
          firstNameMiddleInitial.value = jsonObjectLiterals.data.firstName + (jsonObjectLiterals.data.middleName ? ' ' + jsonObjectLiterals.data.middleName : '')
          lastName.value = jsonObjectLiterals.data.lastName
          address.value = jsonObjectLiterals.data.address
          cityTownStateZipCode.value = [jsonObjectLiterals.data.cityTown, jsonObjectLiterals.data.state, jsonObjectLiterals.data.zipCode].filter(Boolean).join(', ')
          socialSecurityNumber.value = jsonObjectLiterals.data.ssn
          firstDayOfEmployment.value = jsonObjectLiterals.data.firstDayDate

          if (router.currentRoute.params.action === 'edit' || (router.currentRoute.params.action === 'add' && router.currentRoute.query.id)) {
            store.dispatch('app-hr/fetchForm6', { cid: router.currentRoute.query.id })
              .then(response1 => {
                // eslint-disable-next-line
                const jsonObjectLiterals = response1.data.response.data

                // console.log(jsonObjectLiterals.data)

                if (jsonObjectLiterals.data.cid) {
                  form.value = jsonObjectLiterals.data
                }

                form.value.step1Field6 = jsonObjectLiterals.data.step1Field6 ? jsonObjectLiterals.data.step1Field6 : 'Single or Married filing separately'

                if (jsonObjectLiterals.data.signature) {
                  signature.value.fromDataURL(jsonObjectLiterals.data.signature)
                }

                if (form.value.id) {
                  form.value.form = 'folder3_form6'

                  formCopy.value = JSON.parse(JSON.stringify(form.value))

                  store.dispatch('app-hr/fetchFormUpdate', { form: form.value.form, form_id: form.value.id })
                    .then(response2 => {
                      // eslint-disable-next-line
                      const jsonObjectLiterals = response2.data.response.data

                      // console.log(jsonObjectLiterals.data)

                      if (jsonObjectLiterals.data.status) {
                        formUpdate.value = jsonObjectLiterals.data

                        formUpdateStatus.value = formUpdate.value.status
                      }
                    })
                    .catch(error => {
                      console.error(error)
                    })
                }
              })
              .catch(error => {
                console.error(error)
              })
          }
        })
        .catch(error => {
          console.error(error)
        })
    }

    const userData = ref(getUserData())

    const formsOptions = [
      { label: 'Basic Information', name: 'apps-hr-form' },
      { label: 'Employment Checklist', name: 'apps-hr-form1' },
      { label: 'Employment Application', name: 'apps-hr-form2' },
      { label: 'Employment Skills List', name: 'apps-hr-form3' },
      { label: 'Work Experiences Checklist', name: 'apps-hr-form4' },
      { label: 'Employment Eligibility Verification', name: 'apps-hr-form5' },
      { label: 'Form W-4', name: 'apps-hr-form6' },
      { label: 'Non-Compete and Non-Solicitation Agreement', name: 'apps-hr-form7' },
      { label: 'Employment Verification Fax', name: 'apps-hr-form8' },
      { label: 'Ref Inquiry Form', name: 'apps-hr-form9' },
      { label: 'Request for Live Scan Service - Community Care Licensing', name: 'apps-hr-form10' },
      { label: 'New Hire Orientation Acknowledgment Form', name: 'apps-hr-form11' },
      { label: 'Employee Tuberculin Skin Test (TST) and Evaluation', name: 'apps-hr-form12' },
      { label: 'Home Care Organization Inspection Checklist', name: 'apps-hr-form13' },
      { label: 'Personnel Record (Admin)', name: 'apps-hr-form14a' },
      { label: 'Personnel Record', name: 'apps-hr-form14b' },
      { label: 'Applicant for Home Care Aide Registration', name: 'apps-hr-form14c' },
      { label: 'Criminal Record Statement', name: 'apps-hr-form15' },
      { label: 'Statement Acknowledging Requirement to Report Suspected Abuse of Dependent Adults and Elders', name: 'apps-hr-form16' },
      { label: 'Registered Home Care Aide Training Log A', name: 'apps-hr-form17a' },
      { label: 'Registered Home Care Aide Training Log B', name: 'apps-hr-form17b' },
      { label: 'Workplace Safety', name: 'apps-hr-form18' },
      { label: 'Current Knowledge', name: 'apps-hr-form19' },
      { label: 'Fact Sheet (Home Care Services - April 2017)', name: 'apps-hr-form20' },
      { label: 'Acknowledgement Form - Fact Sheet (Home Care Services)', name: 'apps-hr-form21' },
      { label: 'Training Completion Certificate', name: 'apps-hr-form22' },
    ]

    const flatPickrConfig = {
      // wrap: true, // set wrap to true only when using 'input-group'
      // altFormat: 'm/d/Y',
      // altInput: true,
      dateFormat: 'Y-m-d',
    }

    const vueSignaturePadOptions = {
      penColor: 'rgb(0, 0, 0)',
    }

    const sock = new SockJS('https://socket.motivit.com:443/echo')
    const sockId = 'coc-n01'

    return {
      form,

      firstNameMiddleInitial,
      lastName,
      address,
      cityTownStateZipCode,
      socialSecurityNumber,
      firstDayOfEmployment,

      signature,

      formUpdate,
      formUpdateStatus,

      formCopy,

      userData,

      formsOptions,

      flatPickrConfig,
      vueSignaturePadOptions,

      sock,
      sockId,
    }
  },
  data() {
    return {
      action: this.$route.params.action,
      id: this.$route.query.id,

      employersNameAddress: 'Circle of Care, LLC 34145 Pacific Coast Hwy #228 Dana Point CA 92629-2808',
      employerIdentificationNumber: '01-0821766',

      isNotSaved: false,

      forms: this.$route.name,

      formUpdateChanges: [],

      required,
    }
  },
  watch: {
    // eslint-disable-next-line func-names
    'formUpdate.data': function () {
      Object.entries(this.formUpdate.data).forEach(([key, val]) => {
        if (JSON.stringify(val) !== JSON.stringify(this.formCopy[key]) && (Number(val) !== Number(this.formCopy[key]) || String(val) !== String(this.formCopy[key]))) {
          this.formUpdateChanges.push(this.$refs[key].$attrs.title)
        }
      })
    },
  },
  created() {
    this.sock.onopen = () => {
      // console.log('Connection established.')
    }

    this.sock.onmessage = event => {
      const data = JSON.parse(event.data)
      if (data.id === this.sockId) {
        this.getSockMsg(data.msg)
      }
    }

    this.sock.onclose = () => {
      // console.warn('Connection closed.')
    }
  },
  methods: {
    getSockMsg(sockMsg) {
      if (sockMsg === true) {
        // Do something
      }
    },
    sendSockMsg(sockMsg) {
      this.sock.send(JSON.stringify({ id: this.sockId, msg: sockMsg }))
    },
    requestFormUpdate() {
      const self = this

      store
        .dispatch('app-hr/requestFormUpdate', this.formCopy)
        .then(response => {
          // eslint-disable-next-line
          const jsonObjectLiterals = response.data.response.data

          self.formUpdateStatus = 1

          self.sendSockMsg(true)

          // console.log(jsonObjectLiterals.data)
        })
        .catch(error => {
          console.error(error)
        })
    },
    denyFormUpdate() {
      const self = this

      store
        .dispatch('app-hr/denyFormUpdate', this.formCopy)
        .then(response => {
          // eslint-disable-next-line
          const jsonObjectLiterals = response.data.response.data

          self.formUpdateStatus = 0

          if (jsonObjectLiterals.data.id) {
            self.form = jsonObjectLiterals.data
          }

          if (jsonObjectLiterals.data.signature) {
            self.$refs.signature.clearSignature()
            self.$refs.signature.fromDataURL(jsonObjectLiterals.data.signature)
          }

          self.sendSockMsg(true)

          // console.log(jsonObjectLiterals.data)
        })
        .catch(error => {
          console.error(error)
        })
    },
    approveFormUpdate() {
      const self = this

      store
        .dispatch('app-hr/approveFormUpdate', this.formCopy)
        .then(response => {
          // eslint-disable-next-line
          const jsonObjectLiterals = response.data.response.data

          self.formUpdateStatus = 0

          self.sendSockMsg(true)

          // console.log(jsonObjectLiterals.data)
        })
        .catch(error => {
          console.error(error)
        })
    },
    dismissFormUpdate() {
      const self = this

      store
        .dispatch('app-hr/dismissFormUpdate', this.formCopy)
        .then(response => {
          // eslint-disable-next-line
          const jsonObjectLiterals = response.data.response.data

          self.formUpdateStatus = 0

          // console.log(jsonObjectLiterals.data)
        })
        .catch(error => {
          console.error(error)
        })
    },
    validationForm() {
      const self = this

      /* const { isEmpty, data } = this.$refs.signature.saveSignature()
      this.form.signature = !isEmpty ? data : '' */

      this.$refs.simpleRules.validate().then(success => {
        /* this.isNotSaved = true

        store
          .dispatch(this.action === 'add' ? 'app-hr/addForm6' : 'app-hr/updateForm6', this.form)
          .then(response => {
            // eslint-disable-next-line
            const jsonObjectLiterals = response.data.response.data

            this.form.id = jsonObjectLiterals.id

            // console.log(jsonObjectLiterals)

            Swal.fire({
              icon: 'success',
              title: 'Saved!',
              text: 'Saved successfully.',
              showConfirmButton: false,
              timer: 1500,
            })
          })
          .catch(error => {
            console.error(error)

            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Something went wrong!',
              showConfirmButton: false,
              timer: 1500,
            })
          })
          .finally(() => {
            self.isNotSaved = false
          }) */
        if (success) {
          let msg = 'Do you want to save the changes?'
          if (this.action === 'add') msg = 'Do you want to add these data?'
          Swal.fire({
            icon: 'warning',
            title: 'Are you sure?',
            text: msg,
            confirmButtonText: 'Proceed',
            showCancelButton: true,
            reverseButtons: true,
            customClass: {
              confirmButton: 'btn btn-secondary',
              cancelButton: 'btn btn-outline-dark mr-1',
            },
            buttonsStyling: false,
            returnFocus: false,
          }).then(result => {
            if (result.isConfirmed) {
              this.isNotSaved = true

              store
                .dispatch(this.action === 'add' ? 'app-hr/addForm6' : 'app-hr/updateForm6', this.form)
                .then(response => {
                  // eslint-disable-next-line
                  const jsonObjectLiterals = response.data.response.data

                  this.form.id = jsonObjectLiterals.id

                  // console.log(jsonObjectLiterals)

                  if (this.userData.role === 'agent' && this.action === 'edit' && jsonObjectLiterals.count > 0) {
                    self.requestFormUpdate()
                  }

                  Swal.fire({
                    icon: 'success',
                    title: 'Saved!',
                    text: 'Saved successfully.',
                    showConfirmButton: false,
                    timer: 1500,
                  })
                })
                .catch(error => {
                  console.error(error)

                  Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                    showConfirmButton: false,
                    timer: 1500,
                  })
                })
                .finally(() => {
                  self.isNotSaved = false
                })
            }
          })

          // eslint-disable-next-line
          // alert('form submitted!')
        } else {
          Swal.fire({
            icon: 'error',
            // title: 'Are you sure?',
            // eslint-disable-next-line
            text: 'Please fill-up all of the required fields.',
            confirmButtonText: 'OK',
            showCancelButton: false,
            reverseButtons: true,
            customClass: {
              confirmButton: 'btn btn-secondary',
              cancelButton: 'btn btn-outline-dark mr-1',
            },
            buttonsStyling: false,
            returnFocus: false,
          })
        }
      })
    },
    clearSignature() {
      this.$refs.signature.clearSignature()

      const { isEmpty, data } = this.$refs.signature.saveSignature()
      this.form.signature = !isEmpty ? data : ''

      if (this.form.signature === '') {
        this.$refs.simpleRules.refs['employee-signature'].reset()
      }
    },
    undoSignature() {
      this.$refs.signature.undoSignature()

      const { isEmpty, data } = this.$refs.signature.saveSignature()
      this.form.signature = !isEmpty ? data : ''

      if (this.form.signature === '') {
        this.$refs.simpleRules.refs['employee-signature'].reset()
      }
    },
    onBegin() {
      // Do something
    },
    onEnd() {
      const { isEmpty, data } = this.$refs.signature.saveSignature()
      this.form.signature = !isEmpty ? data : ''
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss" scoped>
.hr-forms-select {
  width: 100%;
  min-width: 182px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 135px;
  }
}

@media (max-width: 1199.98px) {
  .hr-forms-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 112px;
    }
  }
}

@media (max-width: 991.98px) {
  .hr-forms-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 598px;
    }
  }
}

@media (max-width: 767.98px) {
  .hr-forms-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 406px;
    }
  }
}

@media (max-width: 575.98px) {
  .hr-forms-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 352px;
    }
  }
}
</style>
